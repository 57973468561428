
/** 
  All of the routes for the Material Dashboard 2 PRO React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that contains other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
 */

// @mui icons
import Icon from "@mui/material/Icon";

// Images
//import profilePicture from "assets/images/team-3.jpg";

const routes = [
  { type: "title", title: "Administration", key: "title-admin", roles: ['ADMIN'] },
  {
    type: "collapse",
    name: "Services",
    key: "services",
    roles: ['ADMIN'],
    icon: <Icon fontSize="medium">apps</Icon>,
    route: "/app/admin/services",
    noCollapse: true
  },
  {
    type: "collapse",
    name: "Users",
    key: "users",
    roles: ['ADMIN'],
    icon: <Icon fontSize="medium">group</Icon>,
    route: "/app/admin/users",
    noCollapse: true
  },{
    type: "collapse",
    name: "Enterprises",
    key: "enterprises",
    roles: ['ADMIN'],
    icon: <Icon fontSize="medium">business</Icon>,
    route: "/app/admin/enterprises",
    noCollapse: true
  },{
    type: "collapse",
    name: "Policy Templates",
    key: "policyTemplates",
    roles: ['ADMIN'],
    icon: <Icon fontSize="medium">policy</Icon>,
    route: "/app/admin/policy-templates",
    noCollapse: true
  }, {
    type: "collapse",
    name: "Packages",
    key: "packages",
    roles: ['ADMIN'],
    icon: <Icon fontSize="medium">inventory_2</Icon>,
    route: "/app/admin/packages",
    noCollapse: true
  }, {
    type: "collapse",
    name: "Messages",
    key: "messages",
    roles: ['ADMIN'],
    icon: <Icon fontSize="medium">forum</Icon>,
    collapse: [
      {
        name: "List",
        key: "messages-list",
        route: "/app/admin/messages",
        roles: ['ADMIN'],
      },{
        name: "Credits",
        key: "messaging-credits",
        route: "/app/admin/messaging-credits",
        roles: ['ADMIN'],
      },{
        name: "Statistics",
        key: "messaging-statistics",
        route: "/app/admin/messaging-statistics",
        roles: ['ADMIN'],
      },
    ]
  }, {
    type: "collapse",
    name: "Settings",
    key: "settings",
    roles: ['ADMIN'],
    icon: <Icon fontSize="medium">settings</Icon>,
    route: "/app/admin/settings",
    noCollapse: true
  },{
    type: "divider", key: "divider-admin",
    roles: ['ADMIN']
  },{
    type: "title", title: "No active enterprise", key: "title-enterprise", roles: ['ADMIN', 'USER']
  },{
    type: "collapse",
    name: "Policies",
    key: "policies",
    roles: ['ADMIN','USER'],
    requiresActiveEnterprise: true,
    icon: <Icon fontSize="medium">policy</Icon>,
    route: "/app/policies",
    noCollapse: true
  },{
    type: "divider", key: 'divider-inventory',
    roles: ['ADMIN', 'MANAGER']
  }



  /*

  {
    type: "collapse",
    name: "Ecommerce",
    key: "ecommerce",
    icon: <Icon fontSize="medium">shopping_basket</Icon>,
    collapse: [
      {
        name: "Products",
        key: "products",
        collapse: [
          {
            name: "New Product",
            key: "new-product",
            route: "/ecommerce/products/new-product",
            component: <NewProduct />,
          },
          {
            name: "Edit Product",
            key: "edit-product",
            route: "/ecommerce/products/edit-product",
            component: <EditProduct />,
          },
          {
            name: "Product Page",
            key: "product-page",
            route: "/ecommerce/products/product-page",
            component: <ProductPage />,
          },
        ],
      },
    ],
  },*/
];

export default routes;
