function apiHost() {
	let api_url='';

	if(process.env.NODE_ENV === 'local' || process.env.NODE_ENV === 'development'){
		//local api url
		api_url = 'http://localhost:8082';
	}

	return api_url;
}

export default apiHost
