import MDBox from "../components/MDBox";
import {useNavigate, useParams} from "react-router-dom";
import {Backdrop, CircularProgress, Icon} from "@mui/material";
import Card from "@mui/material/Card";
import MDTypography from "../components/MDTypography";
import {useCallback, useEffect, useState} from "react";
import MDButton from "../components/MDButton";
import MDInput from "../components/MDInput";
import Switch from "@mui/material/Switch";
import apiHost from "../utils/apiHost";
import Grid2 from "@mui/material/Unstable_Grid2";
import moment from "moment";
import {useSnackbar} from "../useSnackbar";
import {LoadingButton} from "@mui/lab";
import SaveIcon from "@mui/icons-material/Save";
import {useAuth} from "../useAuth";
import Autocomplete from "@mui/material/Autocomplete";
import {DataGrid, GridActionsCellItem, GridFooter, GridFooterContainer} from "@mui/x-data-grid";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";

function Policy() {
    const params = useParams();

    const [isWorking, setIsWorking] = useState(true);
    const [isExisting, setIsExisting] = useState(params.policyId);
    const [policyId, setPolicyId] = useState(params.policyId);
    const [isEdit, setIsEdit] = useState(!params.policyId);
    const [saveEnabled, setSaveEnabled] = useState(true);
    const [policyLoading, setPolicyLoading] = useState(false);
    const [policyLoaded, setPolicyLoaded] = useState(false);

    const [template, setTemplate] = useState(null);
    const [policyName, setPolicyName] = useState("");
    const [enabled, setEnabled] = useState(true);
    const [policyContent, setPolicyContent] = useState("");

    const [applications, setApplications] = useState([]);

    const [descriptionShort, setDescriptionShort] = useState("");
    const [descriptionLong, setDescriptionLong] = useState("");
    const [, setPolicy] = useState("");
    const [wallpaper, setWallpaper] = useState(null);
    const [wallpaperChanged, setWallpaperChanged] = useState(false);

    const [created, setCreated] = useState(null);
    const [modified, setModified] = useState(null);

    const [availableTemplates, setAvailableTemplates] = useState([]);
    const [availableTemplatesLoading, setAvailableTemplatesLoading] = useState(false);
    const [availableTemplatesLoaded, setAvailableTemplatesLoaded] = useState(false);

    //const [loggedOut, setLoggedOut] = useState(false);

    //const { user } = useAuth();
    //const [controller] = useMaterialUIController();
    //const { darkMode, sidenavColor } = controller;
    const { performLoginCheck, activeEnterprise } = useAuth();
    const navigate = useNavigate();
    const {showError, showSuccess} = useSnackbar();

    const loadAvailableTemplates = useCallback(() => {
        setAvailableTemplatesLoading(true);
        setAvailableTemplatesLoaded(false);

        fetch(`${apiHost()}/u-api/policy-templates`, {
            method: 'GET',
            credentials: apiHost().length === 0 ? 'same-origin' : 'include',
        })
            .then(data => {
                if (data.status === 200) {
                    data.json().then(dta => {
                        setAvailableTemplatesLoaded(true);
                        console.log(dta);
                        setAvailableTemplates(dta);

                        if(template) {
                            setTemplate(dta.filter((t) => t.id === template.id)[0]);
                        }

                        setAvailableTemplatesLoading(false);
                    })
                } else {
                    //setLoggedOut(true);
                    if(data.status === 401) {
                        performLoginCheck(loadAvailableTemplates);
                    } else {
                        showError && showError("Request failed (" + data.status + ")");
                    }
                    //setAvailableUsersLoading(false);
                }
            })
            .catch(err => {
                console.log(err);
                setAvailableTemplatesLoading(false);
                showError && showError("Request failed (Network Error?)");
            });
    }, [showError, performLoginCheck, template]);

    const handleCancel = (event) => {
        navigate("/app/policies");
    }

    const handleSave = (event) => {
        setSaveEnabled(false);
        setIsWorking(true);

        /*
        private String googlePolicyId;
        private String policyContent;
         */
        const payload = {
            enterpriseId: activeEnterprise.id,
            name: policyName,
            enabled: enabled,
            descriptionShort: descriptionShort,
            descriptionLong : descriptionLong,
            policyTemplateId: template ? template.id : null,
            // only add apps not in policy itself and remove the temporary 'existing' field
            applicationList: applications.filter((a) => !a.existing).map((b) => {
                delete b['existing'];
                return b;
            }),
            policyContent: null
        }

        if(!template) {
            payload['policyContent'] = policyContent;
        }

        if(wallpaperChanged) {
            payload['wallpaper'] = wallpaper;
        }

        if(isExisting) {
            payload['id'] = policyId;
        }

        fetch(`${apiHost()}/u-api/policy`, {
            method: isExisting ? 'PUT' : 'POST',
            credentials: apiHost().length === 0 ? 'same-origin' : 'include',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(payload)
        })
            .then(data => {
                if(data.status === 200) {
                    data.json().then(dta => {
                        if(dta.success) {
                            if(dta.extended
                                && dta.extended.policyId) {
                                setPolicyId(dta.extended.policyId);
                                setIsEdit(false);
                                setIsExisting(true);
                                //setApplications([]);
                                showSuccess && showSuccess("saved");
                                loadPolicy();
                                // navigate("/app/policy/" + dta.extended.policyId, { replace: true });
                            } else {
                                showError && showError("Invalid response from server (missing ID)");
                            }
                            setSaveEnabled(true);
                            setIsWorking(false);
                        } else {
                            setSaveEnabled(true);
                            setIsWorking(false);
                            showError && showError(dta.reason);
                        }
                    });
                } else {
                    setPolicyLoading(false);
                    setSaveEnabled(true);
                    setIsWorking(false);

                    //setLoggedOut(true);
                    showError && showError("Session expired.");
                    if(data.status === 401) {
                        performLoginCheck(null);
                    }
                }
            })
            .catch(err => {
                console.log(err);
                setSaveEnabled(true);
                setIsWorking(false);
                showError && showError("Request failed (Network Error?)");
            });

    }

    const toggleEdit = () => {
        const isInEdit = isEdit;
        setIsEdit(!isEdit);

        if(isInEdit) {
            loadPolicy();
        }
    }

    const handleEnabledChanged = (event) => {
        if(isEdit || !isExisting) {
            setEnabled(event.target.checked);
        } else {
            event.preventDefault();
        }
    }

    const handleTemplateChanged = useCallback((event, value) => {
        console.debug("handleTemplateChanged - event: " + event);

        setTemplate(value);

        if(value != null && availableTemplates.length > 0) {
            const selectedTemplate = availableTemplates.filter(aT => aT.id === value.id)[0];

            if(descriptionShort === '') {
                setDescriptionShort(selectedTemplate.descriptionShort);
            }

            if(descriptionLong === '') {
                setDescriptionLong(selectedTemplate.descriptionLong);
            }

            if(policyName === '') {
                setPolicyName(selectedTemplate.name);
            }

            const jsonPolicy = JSON.parse(selectedTemplate.policy);
            if(jsonPolicy.hasOwnProperty('applications')) {
                if(jsonPolicy.applications.length > 0) {
                    jsonPolicy.applications.forEach((appl, idx) => {
                        console.debug(appl);
                        appl['existing'] = true;
                        appl['id'] = appl.packageName;
                    });
                }

                const applCopy = [...applications];

                setApplications([...applCopy, ...jsonPolicy.applications]);
            }
        }
    }, [applications, availableTemplates, descriptionLong, descriptionShort, policyName]);

    const getBase64 = (file) => {
        return new Promise(resolve => {
            let baseURL = "";
            // Make new FileReader
            let reader = new FileReader();

            // Convert the file to base64 text
            reader.readAsDataURL(file);

            // on reader load somthing...
            reader.onload = () => {
                baseURL = reader.result;
                resolve(baseURL);
            };
        });
    };

    const handleWallpaperUpload = (event) => {
        getBase64(event.target.files[0])
            .then(result => {
                setWallpaper(result);
                setWallpaperChanged(true);
            })
            .catch(err => {
                showError("Failed to process image");
                console.log(err);
            });
    }

    const loadPolicy = useCallback(() => {
        setPolicyLoading(true);
        setPolicyLoaded(false);
        //setApplications([]);
        console.debug("loadPolicy() - before fetch");

        fetch(`${apiHost()}/u-api/policy/${policyId}`, {
            method: 'GET',
            credentials: apiHost().length === 0 ? 'same-origin' : 'include',
        })
            .then(data => {
                if (data.status === 200) {
                    data.json().then(dta => {
                        console.debug("loadPolicy() - fetch completed");
                        setPolicyId(dta.id);
                        setPolicyName(dta.name);
                        setEnabled(dta.enabled);
                        setCreated(dta.created);
                        setModified(dta.modified);
                        setDescriptionShort(dta.descriptionShort);
                        setDescriptionLong(dta.descriptionLong);
                        setPolicy(dta.policy);

                        if(dta.wallpaper) {
                            fetch(`${apiHost()}/res/file-base64/${dta.wallpaper}`, {
                                method: 'GET',
                                credentials: apiHost().length === 0 ? 'same-origin' : 'include',
                            })
                                .then(data => {
                                    if (data.status === 200) {
                                        data.text().then(dta => {
                                            setWallpaper("data:image/png;base64," + dta);
                                        })
                                    } else {
                                        //setLoggedOut(true);
                                        if(data.status === 401) {
                                            performLoginCheck(null);
                                        } else {
                                            showError("Failed to load wallpaper (" + data.status + ")");
                                        }
                                    }
                                })
                                .catch(err => {
                                    console.log(err);
                                    showError("Failed to load wallpaper (Network Error?)");
                                });
                        }

                        if(dta.applicationList.length > 0) {
                            dta.applicationList.forEach((appl, idx) => {
                                appl['existing'] = false;
                                appl['id'] = appl.packageName;
                            });

                            setApplications(dta.applicationList);
                        } else {
                            setApplications([]);
                        }

                        if(dta.policyTemplateId) {
                            //setTemplate({id: dta.policyTemplateId});
                            //console.debug("calling handleTemplateChanged in loadPolicy - fetch complete");
                            setTemplate({id: dta.policyTemplateId});
                            //handleTemplateChanged(null, {id: dta.policyTemplateId});
                        } else {
                            setTemplate(null);
                        }

                        setPolicyLoaded(true);
                        setPolicyLoading(false);
                    })
                } else {
                    //setLoggedOut(true);
                    if(data.status === 401) {
                        performLoginCheck(loadPolicy);
                    } else {
                        showError("Request failed (" + data.status + ")");
                    }
                }
            })
            .catch(err => {
                console.log(err);
                setPolicyLoading(false);
                showError("Request failed (Network Error?)");
            });
    }, [showError, policyId, performLoginCheck]);

    const onDetachTemplate = () => {
        // get template content and move to policyContent
        setPolicyContent(availableTemplates.filter(aT => aT.id === template.id)[0].policy);
        // deselect template
        setTemplate(null);

        const nextApplications = applications.map(appl => {
            appl.existing = false;
            return appl;
        })

        setApplications(nextApplications);
    }

    function ApplicationsFooter() {
        console.debug("applicationsfooter");
        const [newAppPackage, setNewAppPackage] = useState('');
        const [newAppInstallType, setNewAppInstallType] = useState('PREINSTALLED');
        const [newAppDefaultPermissionPolicy, setNewAppDefaultPermissionPolicy] = useState('GRANT');
        const [newAppAutoUpdateMode, setNewAppAutoUpdateMode] = useState('AUTO_UPDATE_DEFAULT');

        const handleNewAppInstallTypeChanged = (event, value) => {
            setNewAppInstallType(value);
        }

        const handleNewAppDefaultPermissionPolicyChanged = (event, value) => {
            setNewAppDefaultPermissionPolicy(value);
        }

        const handleNewAppAutoUpdateModeChanged = (event, value) => {
            setNewAppAutoUpdateMode(value);
        }

        const handleAddApplication = () => {
            if(!applications.some(e => e.packageName === newAppPackage)) {
                setApplications(
                    [
                        ...applications,
                        {
                            id: newAppPackage,
                            packageName: newAppPackage,
                            installType: newAppInstallType,
                            defaultPermissionPolicy:  newAppDefaultPermissionPolicy,
                            autoUpdateMode: newAppAutoUpdateMode,
                            existing: false
                        }
                    ]
                );

                setNewAppPackage('');
                setNewAppInstallType('PREINSTALLED');
                setNewAppDefaultPermissionPolicy('GRANT');
                setNewAppAutoUpdateMode('AUTO_UPDATE_DEFAULT');
            } else {
                showError("duplicate package name");
            }
        }

        return (
            <GridFooterContainer>
                <MDBox m={1} mt={2.5} display="flex" justifyContent="space-evenly" sx={{
                    ".MuiAutocomplete-root .MuiInputBase-root": {
                        pt: 0,
                        pb: 0
                    },
                    ".MuiAutocomplete-root .MuiInputBase-root .MuiInputBase-input": {
                        pt: 1.5,
                        pb: 1.5
                    }
                }}>
                    <MDInput label="Package" type="text" value={newAppPackage} sx={{width: 260}} disabled={isWorking}
                             onChange={(e) => setNewAppPackage(e.target.value)} />

                    <Autocomplete
                        sx={{ width: 210, ml: 1 }}
                        disabled={isWorking}
                        value={newAppInstallType}
                        onChange={handleNewAppInstallTypeChanged}
                        options={["PREINSTALLED", "FORCE_INSTALLED",
                            "BLOCKED", "AVAILABLE", "REQUIRED_FOR_SETUP", "KIOSK"]}
                        renderInput={
                            (params) =>
                                <MDInput
                                    variant="outlined"
                                    label="Install Type"
                                    {...params}
                                />
                        }
                    />

                    <Autocomplete
                        sx={{ width: 170, ml: 1 }}
                        disabled={isWorking}
                        value={newAppDefaultPermissionPolicy}
                        onChange={handleNewAppDefaultPermissionPolicyChanged}
                        options={["PROMPT", "GRANT", "DENY"]}
                        renderInput={
                            (params) =>
                                <MDInput
                                    variant="outlined"
                                    label="Default Permission Policy"
                                    {...params}
                                />
                        }
                    />

                    <Autocomplete
                        sx={{ width: 260, ml: 1 }}
                        disabled={isWorking}
                        value={newAppAutoUpdateMode}
                        onChange={handleNewAppAutoUpdateModeChanged}
                        options={["AUTO_UPDATE_DEFAULT", "AUTO_UPDATE_POSTPONED", "AUTO_UPDATE_HIGH_PRIORITY"]}
                        renderInput={
                            (params) =>
                                <MDInput
                                    variant="outlined"
                                    label="Update Mode"
                                    {...params}
                                />
                        }
                    />

                    <MDButton sx={{ml: 1}} variant="gradient" color="primary" onClick={handleAddApplication}>Add</MDButton>
                </MDBox>

                <GridFooter  />
            </GridFooterContainer>
        );
    }

    function CustomNoRowsOverlay() {
        return (
            <MDBox sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100%',
            }}>
                No Applications
            </MDBox>
        )
    }

    const renderContent = () => {
        const firstColumn = 2.5;
        const secondColumn = 12 - firstColumn;

        const styleFirstColumn = {
            textAlign: 'right'
        }

        const styleInputs = {
            width: 340
        }

        return (
            <>
                {renderHeading()}

                <MDBox p={2}>
                    <Grid2 container spacing={1.5} mb={2}>
                        <Grid2 item xs={firstColumn} sx={styleFirstColumn}>
                            <MDTypography variant="body2" fontWeight="bold">Enabled</MDTypography>
                        </Grid2>
                        <Grid2 item xs={secondColumn}>
                            <Switch checked={enabled} color="primary" disabled={isWorking}
                                    onChange={handleEnabledChanged} />
                        </Grid2>

                        { isExisting &&
                            <>
                                <Grid2 item xs={firstColumn} sx={styleFirstColumn}>
                                    <MDTypography variant="body2" fontWeight="bold">Created</MDTypography>
                                </Grid2>
                                <Grid2 item xs={secondColumn}>
                                    <MDTypography variant="body2">{moment(created).format('YYYY-MM-DD HH:mm')}</MDTypography>
                                </Grid2>

                                <Grid2 item xs={firstColumn} sx={styleFirstColumn}>
                                    <MDTypography variant="body2" fontWeight="bold">Modified</MDTypography>
                                </Grid2>
                                <Grid2 item xs={secondColumn}>
                                    <MDTypography variant="body2">{moment(modified).format('YYYY-MM-DD HH:mm')}</MDTypography>
                                </Grid2>
                            </>
                        }

                        <Grid2 item xs={firstColumn} sx={styleFirstColumn}>
                            <MDTypography variant="body2" fontWeight="bold">Template</MDTypography>
                        </Grid2>
                        <Grid2 item xs={secondColumn}>
                            {isEdit || !isExisting ?
                                <MDBox display="flex" alignItems="center">
                                    <Autocomplete
                                        sx={{width: 400}} disabled={isWorking}
                                        value={template}
                                        isOptionEqualToValue={(option, value) => option.id === value.id}
                                        onChange={handleTemplateChanged}
                                        options={availableTemplates.map((s) => {
                                            return {label: `${s.name} (${s.descriptionShort})`, id: s.id};
                                        })}
                                        renderInput={
                                            (params) =>
                                                <MDInput
                                                    variant="outlined"
                                                    label="Template"
                                                    fullWidth
                                                    InputLabelProps={{ shrink: true }}
                                                    {...params}
                                                />
                                        }
                                    />
                                    {template &&
                                        <MDButton sx={{ml: 1}} size="small" variant="contained"
                                                  onClick={onDetachTemplate}
                                                  color="warning">Detach</MDButton>
                                    }
                                </MDBox>
                                : <MDTypography variant="body2">{(template && template.label) ? template.label : <i>"No Template applied"</i>}</MDTypography>
                            }
                        </Grid2>

                        <Grid2 item xs={firstColumn} sx={styleFirstColumn}>
                            <MDTypography variant="body2" fontWeight="bold">Name</MDTypography>
                        </Grid2>
                        <Grid2 item xs={secondColumn}>
                            {isEdit || !isExisting ?
                                <MDInput type="text" value={policyName} sx={styleInputs} disabled={isWorking}
                                         onChange={(e) => setPolicyName(e.target.value)} />
                                : <MDTypography variant="body2">{policyName}</MDTypography>
                            }
                        </Grid2>

                        <Grid2 item xs={firstColumn} sx={styleFirstColumn}>
                            <MDTypography variant="body2" fontWeight="bold">Description Short</MDTypography>
                        </Grid2>
                        <Grid2 item xs={secondColumn}>
                            {isEdit || !isExisting ?
                                <MDInput type="text" value={descriptionShort} sx={styleInputs} disabled={isWorking}
                                         onChange={(e) => setDescriptionShort(e.target.value)} />
                                : <MDTypography variant="body2">{descriptionShort}</MDTypography>
                            }
                        </Grid2>

                        <Grid2 item xs={firstColumn} sx={styleFirstColumn}>
                            <MDTypography variant="body2" fontWeight="bold">Description Long</MDTypography>
                        </Grid2>
                        <Grid2 item xs={secondColumn}>
                            {isEdit || !isExisting ?
                                <MDInput multiline maxRows={3} type="text" value={descriptionLong} sx={{width: 400}} disabled={isWorking}
                                         onChange={(e) => setDescriptionLong(e.target.value)} />
                                : <MDTypography variant="body2">{descriptionLong}</MDTypography>
                            }
                        </Grid2>

                        <Grid2 item xs={firstColumn} sx={styleFirstColumn}>
                            <MDTypography variant="body2" fontWeight="bold">Wallpaper</MDTypography>
                        </Grid2>
                        <Grid2 item xs={secondColumn} display="flex">
                            <MDBox
                                display={wallpaper ? "inherit" : "none"}
                                component="img"
                                src={wallpaper}
                                alt="wallpaper"
                                borderRadius="lg"
                                variant="contained"
                                shadow="md"
                                height={80}
                                style={{objectFit: 'contain', border: '#7d7a7a26 solid 1px'}}
                            />
                            { (isEdit || !isExisting) &&
                                <MDButton circular={true} variant="gradient" color="info" size="small"
                                          component="label" sx={{m: 2}} disabled={isWorking}
                                          startIcon={<AddPhotoAlternateIcon />}>
                                    { isExisting ? "Change" : "Upload" }
                                    <input hidden accept="image/png" type="file" onChange={handleWallpaperUpload} />
                                </MDButton>
                            }
                        </Grid2>

                        <Grid2 item xs={firstColumn} sx={styleFirstColumn}>
                            <MDTypography variant="body2" fontWeight="bold">Applications</MDTypography>
                        </Grid2>
                        <Grid2 item xs={secondColumn}>
                            <DataGrid columns={[
                                { field: 'packageName', headerName: 'Package', type: 'string', flex: 0.5 },
                                { field: 'installType', headerName: 'Install Type', type: 'string', flex: 0.5 },
                                { field: 'defaultPermissionPolicy', headerName: 'Permission Policy', type: 'string', flex: 0.5 },
                                { field: 'autoUpdateMode', headerName: 'Auto Update Mode', type: 'string', flex: 0.5},
                                {
                                    field: 'actions', headerName: "", type: 'actions',
                                    align: 'right', width: 40,
                                    getActions: (params) => {
                                        if(isEdit && !params.row.existing) {
                                            return [
                                                <GridActionsCellItem
                                                    icon={<Icon color="error">
                                                        delete
                                                    </Icon>}
                                                    onClick={(parms) => {
                                                        setApplications(applications.filter(
                                                            a => a.packageName !== params.row.packageName));
                                                    }}
                                                    label="Delete" />
                                            ];
                                        } else {
                                            return [];
                                        }
                                    }
                                }
                            ]}
                            rows={applications} autoHeight={true} density="compact"
                            getRowId={(row) => row.packageName}
                            hideFooterPagination={true} hideFooterSelectedRowCount={true}
                            pageSize={100}
                            disableSelectionOnClick={true}
                            disableColumnSelector={true}
                            initialState={{
                                sorting: {
                                    sortModel: [{ field: 'name', sort: 'asc' }],
                                },
                            }}
                            components={{
                                Footer: isEdit ? ApplicationsFooter : GridFooterContainer,
                                NoRowsOverlay: CustomNoRowsOverlay,
                            }}>

                            </DataGrid>
                        </Grid2>

                        { !template &&
                            <>
                                <Grid2 item xs={firstColumn} sx={styleFirstColumn}>
                                    <MDTypography variant="body2" fontWeight="bold">Policy Content</MDTypography>
                                </Grid2>
                                <Grid2 item xs={secondColumn}>
                                    {(isEdit || !isExisting) ?
                                        <MDInput multiline rows={20} type="text" value={policyContent} sx={{width: '100%'}} disabled={isWorking}
                                                 onChange={(e) => setPolicyContent(e.target.value)} />
                                        : <MDTypography variant="body2" sx={{whiteSpace: 'pre-wrap'}}>{policyContent}</MDTypography>
                                    }
                                </Grid2>
                            </>
                        }
                    </Grid2>
                </MDBox>

                {((isExisting && isEdit) || !isExisting) &&
                    <MDBox p={2} display="flex" justifyContent="center">
                        {!isExisting &&
                            <MDButton sx={{m: 1}} variant="text" color="secondary" disabled={isWorking}
                                      onClick={handleCancel}>Cancel</MDButton>
                        }
                        <LoadingButton
                            disabled={isWorking ? true : !saveEnabled}
                            sx={{m: 1}}
                            color="primary"
                            onClick={handleSave}
                            loading={isWorking}
                            loadingPosition="start"
                            startIcon={<SaveIcon />}
                            variant="contained"
                        >
                            Save
                        </LoadingButton>
                    </MDBox>
                }
            </>
        );
    }

    const renderHeading = () => {
        return (
            <MDBox p={2} display="flex" justifyContent="space-between">
                <MDTypography variant="h3">
                    {
                        isExisting ?
                            (isEdit ?
                                "Edit: " + policyName
                                : policyName
                            )
                            : "New Policy"
                    }
                </MDTypography>
                {
                    isExisting &&
                        <MDButton variant="gradient" color="primary" disabled={isWorking}
                                  onClick={toggleEdit}>{isEdit ? "Cancel Edit" : "Edit"}</MDButton>
                }
            </MDBox>
        );
    }

    useEffect(() => {
        console.debug("effect: availableTemplatesLoaded && template && !template.label");
        if(availableTemplatesLoaded && template && !template.label) {
            console.debug("calling handleTemplateChanged in useEffect - availableTemplatesLoaded")
            handleTemplateChanged(null, availableTemplates.filter((t) => t.id === template.id).map(sT => {
                return {label: `${sT.name} (${sT.descriptionShort})`, id: sT.id};
            })[0]);
            /*
            setTemplate(
                availableTemplates.filter((t) => t.id === template.id).map(sT => {
                    return {label: `${sT.name} (${sT.descriptionShort})`, id: sT.id};
                })[0]
            );

             */
        }
    }, [availableTemplatesLoaded, template, availableTemplates, handleTemplateChanged])

    useEffect(() => {
        console.debug("effect: availableTemplatesLoaded && ((isExisting && policyLoaded) || !isExisting");
        //if(!loggedOut) {
            if(availableTemplatesLoaded && ((isExisting && policyLoaded) || !isExisting)) {
                setIsWorking(false);
            } else {
                setIsWorking(true);
            }
        //}

    }, [availableTemplatesLoaded, isExisting, policyLoaded, setIsWorking])

    useEffect(() => {
        console.debug("effect: params.policyId && !policyLoaded && !policyLoading");
        //if(!loggedOut) {
            if(params.policyId && !policyLoaded && !policyLoading) {
                loadPolicy();
            }
        //}
    }, [params.policyId, loadPolicy, policyLoaded, policyLoading]);

    useEffect(() => {
        console.debug("effect: !availableTemplatesLoaded && !availableTemplatesLoading");
        //if(!loggedOut) {
            if(!availableTemplatesLoaded && !availableTemplatesLoading) {
                loadAvailableTemplates();
            }
        //}
    }, [availableTemplatesLoaded, availableTemplatesLoading, loadAvailableTemplates, setAvailableTemplates,
        setAvailableTemplatesLoading, setAvailableTemplatesLoaded, showError]);

    return (
        <MDBox mb={2} flex={2}>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={isWorking}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            {
                !isWorking &&
                (
                    <Card sx={{width: "100%"}}>
                        {renderContent()}
                    </Card>
                )

            }
        </MDBox>
    );
}

export default Policy;


Policy.defaultProps = {

};

Policy.propTypes = {

};