// Material Dashboard 2 PRO React Base Styles
import colors from "assets/theme/base/colors";

const { white } = colors;

const loadingButton = {
  styleOverrides: {
    root: {
      color: white.main,
    },
  },
};

export default loadingButton;
