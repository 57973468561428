import React from "react";
import {BrowserRouter} from "react-router-dom";
import App from "App";
import {AuthProvider} from "./useAuth";
import {MaterialUIControllerProvider} from "context";
import {createRoot} from 'react-dom/client';
import {SnackbarProvider} from "./useSnackbar";

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
    <BrowserRouter>
        <MaterialUIControllerProvider>
            <AuthProvider>
                <SnackbarProvider>
                    <App />
                </SnackbarProvider>
            </AuthProvider>
        </MaterialUIControllerProvider>
    </BrowserRouter>
);
