import PropTypes from "prop-types";

// @mui material components
import Container from "@mui/material/Container";
import Link from "@mui/material/Link";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React base styles
import typography from "assets/theme/base/typography";

function PublicFooter({ light }) {
    const { size } = typography;

    return (
        <MDBox position="absolute" width="100%" bottom={0} py={4}>
            <Container>
                <MDBox
                    width="100%"
                    display="flex"
                    flexDirection={{ xs: "column", lg: "row" }}
                    justifyContent="center"
                    alignItems="center"
                    px={1.5}
                >
                    <MDBox
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        flexWrap="wrap"
                        color={light ? "white" : "text"}
                        fontSize={size.sm}
                    >
                        &copy; {new Date().getFullYear()}, made by&nbsp;

                        <Link href="https://vileala.ch/" target="_blank">
                            <MDTypography variant="button" fontWeight="medium" color={light ? "white" : "dark"}>
                                Vileala GmbH&nbsp;
                            </MDTypography>
                        </Link>
                    </MDBox>

                </MDBox>
            </Container>
        </MDBox>
    );
}

// Setting default props for the Footer
PublicFooter.defaultProps = {
    light: false,
};

// Typechecking props for the Footer
PublicFooter.propTypes = {
    light: PropTypes.bool,
};

export default PublicFooter;
