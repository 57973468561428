import React, {createContext, useContext, useState} from "react";
import apiHost from "./utils/apiHost";
import Cookies from 'universal-cookie';
import {useNavigate} from "react-router-dom";
import {Backdrop, Dialog, DialogActions, DialogContent, DialogTitle, Slide} from "@mui/material";
import MDButton from "./components/MDButton";
import MDAlert from "./components/MDAlert";
import MDBox from "./components/MDBox";
import MDInput from "./components/MDInput";

// Create the context
const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {

    // Using the useState hook to keep track of the value authed (if a
    // user is logged in)
    const [user, setUser] = useState(null);
    const [activeEnterprise, setActiveEnterprise] = useState(null);
    const [loginExpired, setLoginExpired] = useState(false);

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [loginFailed, setLoginFailed] = useState(false);
    const [postLoginFunction, setPostLoginFunction] = useState(null);

    let navigate = useNavigate();

    const performLoginCheck = (callbackFunc) => {
        console.debug("performLoginCheck()");
        setPostLoginFunction(() => callbackFunc);

        loginCheck().then((user) => {
            if (!user) {
                console.debug("setLoginExpired(true)");
                setLoginExpired(true);
            } else {
                setLoginExpired(false);
                setUser(user);
            }
        });
    }

    const loginUser = async (credentials) => {
        const host = apiHost();

        return fetch(`${host}/u-api/auth/signin`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: `username=${credentials.user}&password=${credentials.pass}`,
            credentials: apiHost().length === 0 ? 'same-origin' : 'include'
        })
            .then(data => {
                if(data.status === 401) {
                    // login failed or account not enabled
                    return null;
                } else if(data.status === 403) {
                    // login succeeded, but email verification pending
                    // TODO: send user to a form to re-send verification email
                    return null;
                } else if(data.status === 200) {
                    return data.json();
                } else {
                    console.debug("Login failed. Status code: " + data.statusCode);
                    return null;
                }
            })
            .catch(err => {
                console.log(err);
                return null;
            })
    }

    const performLogin = async (event) => {
        event.preventDefault();
        setLoginFailed(false);

        await loginUser({
            user: username,
            pass: password,
        }).then(async (user) => {
            if (user !== null) {
                document.cookie = "username=" + user.username + "; samesite=strict; path=/" + (apiHost().length === 0 ? "; secure" : "");
                setUser(user);
                setLoginExpired(false);
                if(postLoginFunction) {
                    console.debug("calling post login function");
                    setUsername('');
                    postLoginFunction();
                    setPostLoginFunction(null);
                }
            } else {
                setLoginFailed(true);
            }
            setPassword('');
        });
    }

    const renderLoginModal = () => {
        return (
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loginExpired}>

                <Slide appear={false} direction="up" in={loginExpired}
                       style={{ transitionDelay: `200ms` }}
                       timeout={{
                            appear: 300,
                            enter: 300,
                            exit: 300,
                        }}>
                    <Dialog open={loginExpired}
                            keepMounted
                            fullWidth={true}
                            maxWidth="xs">
                        <DialogTitle sx={{textAlign: 'center'}}>Session Expired</DialogTitle>
                        <DialogContent>
                            <MDBox pt={4} pb={3} px={3}>
                                { loginFailed &&
                                    <MDAlert mb={2} color="error">Login failed</MDAlert>
                                }
                                <MDBox component="form" role="form">
                                    <MDBox mb={2}>
                                        <MDInput type="email" label="Email" fullWidth
                                                 value={username}
                                                 onChange={(e) => setUsername(e.target.value)}
                                        />
                                    </MDBox>
                                    <MDBox mb={2}>
                                        <MDInput type="password" label="Password" fullWidth
                                                 value={password}
                                                 onChange={(e) => setPassword(e.target.value)}
                                                 onKeyDown={e => e.key === 'Enter' && performLogin(e)}
                                        />
                                    </MDBox>
                                </MDBox>
                            </MDBox>

                        </DialogContent>
                        <DialogActions>
                            <MDButton color="primary" onClick={performLogin}>Login</MDButton>
                        </DialogActions>
                    </Dialog>
                </Slide>

            </Backdrop>
        );
    }

    const loginCheck = async () => {
        // check cookie
        const cookies = new Cookies();
        const cookieUsername = cookies.get('username');

        if(cookieUsername !== undefined) {
            // ping backend
            return fetch(`${apiHost()}/u-api/login/check`, {
                method: 'GET',
                credentials: apiHost().length === 0 ? 'same-origin' : 'include'
            })
                .then(data => {
                    if(data.status === 200) {
                        return data.json();
                    } else {
                        cookies.remove('username');
                        return null;
                    }
                })
                .catch(err => {
                    cookies.remove('username');
                    return null;
                });
        } else {
            return null;
        }
    }

    const logout = async () => {
        const cookies = new Cookies();

        fetch(`${apiHost()}/u-api/auth/logout`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: apiHost().length === 0 ? 'same-origin' : 'include'
        })
            .then(() => {
                cookies.remove('username');
                setUser(null);
                navigate('/app/login');
            })
            .catch(err => {
                console.log(err);
            })
    };

    return (
        // Using the provider so that ANY component in our application can
        // use the values that we are sending.
        <AuthContext.Provider value={{ user, setUser, activeEnterprise, setActiveEnterprise,
            logout, loginCheck, performLoginCheck, renderLoginModal }}>
            {children}
        </AuthContext.Provider>
    );
};

// Finally creating the custom hook
export const useAuth = () => useContext(AuthContext);
