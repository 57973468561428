// Material Dashboard 2 PRO React Base Styles
import colors from "assets/theme-dark/base/colors";
import borders from "assets/theme-dark/base/borders";

// Material Dashboard 2 PRO React Helper Function
import rgba from "assets/theme-dark/functions/rgba";

const { black, white, background } = colors;
const { borderWidth } = borders;

const tablePagination = {
  styleOverrides: {
    root: {
      color: white.main,
      backgroundColor: background.card,
      border: `${borderWidth[0]} solid ${rgba(black.main, 0.125)}`,
    },
  },
};

export default tablePagination;
