import {setLayout, setMiniSidenav, setOpenConfigurator, useMaterialUIController} from "../context";
import Sidenav from "../components/Sidenav";
import routes from "../routes";
import Configurator from "../components/Configurator";
import {useEffect, useState} from "react";
import MDBox from "../components/MDBox";
import Icon from "@mui/material/Icon";
import {useAuth} from "../useAuth";
import {Outlet, useLocation, useNavigate} from "react-router-dom";
import DashboardNavbar from "../components/Navbars/DashboardNavbar";
import DashboardLayout from "../components/DashboardLayout";
import Footer from "../components/Footer";

import imageLogo from "assets/images/brand-logo_200x56.png";
import imageLogoDark from "assets/images/brand-logo_light_200x56.png"; // used on dark background

function ProtectedLayout() {
    const { user, setUser, loginCheck } = useAuth();

    const [controller, dispatch] = useMaterialUIController();
    const { pathname } = useLocation();
    const {
        miniSidenav,
        layout,
        openConfigurator,
        sidenavColor,
        transparentSidenav,
        whiteSidenav,
        darkMode,
    } = controller;

    let navigate = useNavigate();

    useEffect(() => {
        setLayout(dispatch, "dashboard");
    }, [dispatch, pathname]);

    useEffect(() => {
        if(user == null) {
            loginCheck().then((user) => {
                if (!user) {
                    navigate('/app/login');
                } else {
                    setUser(user);
                }
            });
        }
    },
    [loginCheck, navigate, setUser, user]);

    const [onMouseEnter, setOnMouseEnter] = useState(false);

    // Open sidenav when mouse enter on mini sidenav
    const handleOnMouseEnter = () => {
        if (miniSidenav && !onMouseEnter) {
            setMiniSidenav(dispatch, false);
            setOnMouseEnter(true);
        }
    };

    // Close sidenav when mouse leave mini sidenav
    const handleOnMouseLeave = () => {
        if (onMouseEnter) {
            setMiniSidenav(dispatch, true);
            setOnMouseEnter(false);
        }
    };

    // Change the openConfigurator state
    const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);

    const configsButton = (
        <MDBox
            display="flex"
            justifyContent="center"
            alignItems="center"
            width="3.25rem"
            height="3.25rem"
            bgColor="white"
            shadow="sm"
            borderRadius="50%"
            position="fixed"
            right="2rem"
            bottom="2rem"
            zIndex={99}
            color="dark"
            sx={{ cursor: "pointer" }}
            onClick={handleConfiguratorOpen}
        >
            <Icon fontSize="small" color="inherit">
                settings
            </Icon>
        </MDBox>
    );

    return (
            !!user ? (
            <>
                {(layout !== "vr") && (
                    <>
                        <Sidenav
                            color={sidenavColor}
                            brand={(transparentSidenav && !darkMode) || whiteSidenav ? imageLogo : imageLogoDark}
                            brandName="EMC"
                            routes={routes}
                            onMouseEnter={handleOnMouseEnter}
                            onMouseLeave={handleOnMouseLeave}
                        />
                        <Configurator/>
                        {configsButton}
                    </>
                )
                }
                {(layout === "vr") && <Configurator/>}
                <DashboardLayout>
                    <DashboardNavbar />
                    <Outlet flex={2} />
                    <Footer />
                </DashboardLayout>
            </>) : <div>loading...</div>


    )
}

export default ProtectedLayout;
