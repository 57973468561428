// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";

function Dashboard() {

    return (
                <MDBox py={3} flex={2}>
                    <Grid container>
                        <div />
                    </Grid>
                    <MDBox mt={6}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={6} lg={4}>
                                <MDBox mb={3}>
                                    <div
                                    />
                                </MDBox>
                            </Grid>
                            <Grid item xs={12} md={6} lg={4}>
                                <MDBox mb={3}>
                                    <div
                                    />
                                </MDBox>
                            </Grid>
                            <Grid item xs={12} md={6} lg={4}>
                                <MDBox mb={3}>
                                    <div

                                    />
                                </MDBox>
                            </Grid>
                        </Grid>
                    </MDBox>
                    <MDBox mt={1.5}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={6} lg={3}>
                                <MDBox mb={1.5}>
                                    <div

                                    />
                                </MDBox>
                            </Grid>
                            <Grid item xs={12} md={6} lg={3}>
                                <MDBox mb={1.5}>
                                    <div

                                    />
                                </MDBox>
                            </Grid>
                            <Grid item xs={12} md={6} lg={3}>
                                <MDBox mb={1.5}>
                                    <div

                                    />
                                </MDBox>
                            </Grid>
                            <Grid item xs={12} md={6} lg={3}>
                                <MDBox mb={1.5}>
                                    <div

                                    />
                                </MDBox>
                            </Grid>
                        </Grid>
                    </MDBox>
                    <MDBox mt={2}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={6} lg={4}>
                                <MDBox mt={3}>
                                    <div

                                    />
                                </MDBox>
                            </Grid>
                            <Grid item xs={12} md={6} lg={4}>
                                <MDBox mt={3}>
                                    <div

                                    />
                                </MDBox>
                            </Grid>
                            <Grid item xs={12} md={6} lg={4}>
                                <MDBox mt={3}>
                                    <div

                                    />
                                </MDBox>
                            </Grid>
                        </Grid>
                    </MDBox>
                </MDBox>
    );
}

export default Dashboard;
