// Material Dashboard 2 PRO React Base Styles
import colors from "assets/theme/base/colors";
import borders from "assets/theme/base/borders";

// Material Dashboard 2 PRO React Helper Function
import rgba from "assets/theme/functions/rgba";

const { black, white } = colors;
const { borderWidth } = borders;

const tablePagination = {
  styleOverrides: {
    root: {
      backgroundColor: white.main,
      border: `${borderWidth[0]} solid ${rgba(black.main, 0.125)}`,
    },
  },
};

export default tablePagination;
