import MDBox from "../components/MDBox";
import MDButton from "../components/MDButton";
import {useCallback, useEffect, useState} from "react";
import apiHost from "../utils/apiHost";
import {DataGrid, GridActionsCellItem} from "@mui/x-data-grid";
import {useNavigate} from "react-router-dom";
import moment from "moment";
import {useSnackbar} from "../useSnackbar";
import {useAuth} from "../useAuth";


function Policies() {
    const [policiesLoaded, setPoliciesLoaded] = useState(false);
    const [policiesLoading, setPoliciesLoading] = useState(false);
    const [policies, setPolicies] = useState([]);

    const {showError} = useSnackbar();
    //const { user } = useAuth();
    //const [controller] = useMaterialUIController();
    //const { darkMode, sidenavColor } = controller;
    const navigate = useNavigate();
    const { performLoginCheck, activeEnterprise } = useAuth();

    const handleDelete = (policyId) => {
        fetch(`${apiHost()}/u-api/policy/${policyId}`, {
            method: 'DELETE',
            credentials: apiHost().length === 0 ? 'same-origin' : 'include',
        })
            .then(data => {
                if(data.status === 200) {
                    data.json().then(dta => {
                        if(dta.success) {
                            loadPolicies();
                        } else {
                            showError("delete failed");
                        }
                    })
                }
            })
            .catch(err => {
                console.log(err);
                showError && showError("Request failed (Network Error?)");
            });
    }

    const columns = [
        { field: 'enabled', headerName: 'Enabled', type: 'boolean' },
        { field: 'name', headerName: 'Name', type: 'string', flex: 1 },
        { field: 'descriptionShort', headerName: 'Description', type: 'string', flex: 1 },
        {
            field: 'modified', headerName: 'Last updated', type: 'dateTime', width: 240,
            valueFormatter: ({ value }) => moment(value).fromNow(),
        },
        {
            field: 'actions',
            type: 'actions',
            align: 'right',
            flex: 0.8,
            getActions: (params) => [
                <GridActionsCellItem showInMenu={true} onClick={(parms) => {
                    navigate("/app/policy/" + params.id);
                }} label="Edit" />,
                <GridActionsCellItem showInMenu={true} onClick={(parms) => {
                    handleDelete(params.id);
                }} label="Delete" />,

            ]
        }
    ];

    const handleAdd = () => {
        navigate("/app/policy");
    }

    const loadPolicies = useCallback(() => {
        setPoliciesLoading(true);

        fetch(`${apiHost()}/u-api/policies/${activeEnterprise.id}`, {
            method: 'GET',
            credentials: apiHost().length === 0 ? 'same-origin' : 'include',
        })
            .then(data => {
                if(data.status === 200) {
                    data.json().then(dta => {
                        setPoliciesLoaded(true);
                        console.log(dta);
                        setPolicies(dta);
                        setPoliciesLoading(false);
                    })
                } else {
                    if(data.status === 401) {
                        performLoginCheck(loadPolicies);
                    } else {
                        showError && showError("Request failed (" + data.status + ")");
                    }
                    //setEnterprisesLoading(false);
                    //showError && showError("Request failed (" + data.status + ")");
                }
            })
            .catch(err => {
                console.log(err);
                setPoliciesLoading(false);
                showError && showError("Request failed (Network Error?)");
            });
    }, [showError, performLoginCheck, activeEnterprise.id]);

    useEffect(() => {
        if(activeEnterprise && !policiesLoaded && !policiesLoading) {
            loadPolicies();
        }
    }, [activeEnterprise, policiesLoaded, policiesLoading, loadPolicies, setPolicies,
        setPoliciesLoaded, setPoliciesLoading, showError]);

    return (
        <MDBox mb={2} flex={2}>
            { activeEnterprise ?
                <>
                    <MDBox mb={2} display="flex" justifyContent="right">
                        <MDButton variant="gradient" color="primary" circular={true} onClick={handleAdd}>Add</MDButton>
                    </MDBox>
                    <DataGrid columns={columns} rows={policies} autoHeight={true}
                              disableSelectionOnClick={true}
                              disableColumnSelector={true}
                              getRowHeight={() => 'auto'}
                              initialState={{
                                  sorting: {
                                      sortModel: [{ field: 'name', sort: 'asc' }],
                                  },
                              }}
                    />
                </> :
                <MDBox>No Enterprise selected/assigned</MDBox>
            }

        </MDBox>
    );
}

export default Policies;


Policies.defaultProps = {
};

Policies.propTypes = {
};