// Material Dashboard 2 PRO React Base Styles
//import colors from "assets/theme-dark/base/colors";

//const { white } = colors;

const loadingButton = {
  styleOverrides: {
    root: {
    },
  },
};

export default loadingButton;
