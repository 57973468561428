import {useEffect} from "react";

// react-router-dom components
import {Navigate, Outlet, useLocation} from "react-router-dom";


// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";

// Material Dashboard 2 PRO React context
import {setLayout, useMaterialUIController} from "context";
import {useAuth} from "../useAuth";
import Grid from "@mui/material/Grid";
import Footer from "./PublicFooter";

function PublicPageLayout({ background, image }) {
    const { user } = useAuth();

    const [, dispatch] = useMaterialUIController();
    const { pathname } = useLocation();


    useEffect(() => {
        setLayout(dispatch, "page");
    }, [dispatch, pathname]);

    if (user) {
        return <Navigate to="/dashboard" />;
    }

    return (
        <MDBox
            width="100vw"
            height="100%"
            minHeight="100vh"
            bgColor={background}
            sx={{ overflowX: "hidden" }}
        >
            <MDBox
                position="absolute"
                width="100%"
                minHeight="100vh"
                sx={{
                    backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
                        image &&
                        `${linearGradient(
                            rgba(gradients.dark.main, 0.6),
                            rgba(gradients.dark.state, 0.6)
                        )}, url(${image})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                }}
            />
            <MDBox px={1} width="100%" height="100vh" mx="auto">
                <Grid container spacing={1} justifyContent="center" alignItems="center" height="100%">
                    <Grid item xs={11} sm={9} md={5} lg={4} xl={3}>
                        <Outlet />
                    </Grid>
                </Grid>
            </MDBox>
            <Footer light />

        </MDBox>
    );
}

// Setting default values for the props for PageLayout
PublicPageLayout.defaultProps = {
    background: "default",
};

// Typechecking props for the PageLayout
PublicPageLayout.propTypes = {
    image: PropTypes.string.isRequired,
    background: PropTypes.oneOf(["white", "light", "default"]),
};

export default PublicPageLayout;
