import {lazy, Suspense, useEffect} from "react";

// react-router components
import {Navigate, Route, Routes, useLocation, useNavigate} from "react-router-dom";
import { ErrorBoundary } from 'react-error-boundary'

// @mui material components
import {ThemeProvider} from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

// Material Dashboard 2 PRO React themes
import theme from "assets/theme";

// Material Dashboard 2 PRO React Dark Mode themes
import themeDark from "assets/theme-dark";

// Material Dashboard 2 PRO React contexts
import {useMaterialUIController} from "context";

// Images
import bgImage from "assets/images/bg-cloudygreen.png";
import Dashboard from "./pages/Dashboard";
import Login from "./pages/Login";
import ProtectedLayout from "./layouts/ProtectedLayout";
import PublicPageLayout from "./layouts/PublicPageLayout";
import {useAuth} from "./useAuth";
import Policies from "./pages/Policies";
import Policy from "./pages/Policy";
import {useSnackbar} from "./useSnackbar";

const AdminUsers = lazy(() => import("./pages/AdminUsers"));
const AdminSettings = lazy(() => import("./pages/AdminSettings"));
const AdminEnterprises = lazy(() => import("./pages/AdminEnterprises"));
const AdminEnterprise = lazy(() => import("./pages/AdminEnterprise"));
const PolicyTemplates = lazy(() => import("./pages/PolicyTemplates"));
const PolicyTemplate = lazy(() => import("./pages/PolicyTemplate"));
const AdminServices = lazy(() => import("./pages/AdminServices"));
const Packages = lazy(() => import("./pages/Packages"));
const Package = lazy(() => import("./pages/Package"));
const Messages = lazy(() => import("./pages/Messages"));
const MessagingCredits = lazy(() => import("./pages/MessagingCredits"));
const MessagingStatistics = lazy(() => import("./pages/MessagingStatistics"));


function AdminErrorFallback({ error, resetErrorBoundary }) {
  return (
      <div role="alert">
        <p>Something went wrong:</p>
        <pre>{error.message}</pre>
        <button onClick={resetErrorBoundary}>Try again</button>
      </div>
  )
}

export default function App() {
  const { renderLoginModal } = useAuth();
  const { renderSnackbar } = useSnackbar();
  const [controller, dispatch] = useMaterialUIController();
  const {
    direction,
    darkMode,
  } = controller;

  const { pathname } = useLocation();
  const navigate = useNavigate();

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  useEffect(() => {
    console.debug(dispatch);
  }, [dispatch]);

  return (
    <ThemeProvider theme={darkMode ? themeDark : theme}>
      <CssBaseline />
      {renderSnackbar()}
      {renderLoginModal()}
      <Suspense fallback={<p>Loading...</p>}>
        <ErrorBoundary FallbackComponent={AdminErrorFallback}
                       onReset={() => navigate("/app")} >
          <Routes>
            {/*getRoutes(routes)*/}
            <Route path="/" element={<Navigate to="/app" />}/>
            <Route path="/app" element={<ProtectedLayout />}>
              <Route path="*" element={<Dashboard />} />
              <Route path="admin">
                  <Route path="*" element={<div>Admin</div>} />
                  <Route path="services" element={<AdminServices />}></Route>
                  <Route path="users" element={<AdminUsers />}></Route>
                  <Route path="enterprises" element={<AdminEnterprises />}></Route>
                  <Route path="enterprises/:enterpriseId" element={<AdminEnterprise />}></Route>
                  <Route path="enterprise" element={<AdminEnterprise />}></Route>
                  <Route path="policy-template/:policyId" element={<PolicyTemplate />}></Route>
                  <Route path="policy-template" element={<PolicyTemplate />}></Route>
                  <Route path="policy-templates" element={<PolicyTemplates />}></Route>
                  <Route path="packages" element={<Packages />}></Route>
                  <Route path="package" element={<Package />}></Route>
                  <Route path="package/:packageId" element={<Package />}></Route>
                  <Route path="messages" element={<Messages />}></Route>
                  <Route path="messaging-credits" element={<MessagingCredits />}></Route>
                  <Route path="messaging-statistics" element={<MessagingStatistics />}></Route>
                  <Route path="settings" element={<AdminSettings />}></Route>


              </Route>

              <Route path="policies" element={<Policies />}></Route>
              <Route path="policy" element={<Policy />}></Route>
              <Route path="policy/:policyId" element={<Policy />}></Route>

            </Route>

            <Route element={<PublicPageLayout background="default" image={bgImage} />}>
              <Route path="/app/login" element={<Login />} />
            </Route>

          </Routes>
        </ErrorBoundary>
      </Suspense>
    </ThemeProvider>
  );
}
