import React, {createContext, useContext, useState} from "react";
import {Alert, Snackbar} from "@mui/material";

// Create the context
const SnackbarContext = createContext(null);

export const SnackbarProvider = ({ children }) => {

    // Using the useState hook to keep track of the value authed (if a
    // user is logged in)

    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarSeverity, setSnackbarSeverity] = useState("info");
    const [snackbarMessage, setSnackbarMessage] = useState("");

    const showError = (msg) => {
        setSnackbarSeverity("error")
        setSnackbarMessage(msg);
        setSnackbarOpen(true);
    }

    const showWarning = (msg) => {
        setSnackbarSeverity("warning")
        setSnackbarMessage(msg);
        setSnackbarOpen(true);
    }

    const showInfo = (msg) => {
        setSnackbarSeverity("info")
        setSnackbarMessage(msg);
        setSnackbarOpen(true);
    }

    const showSuccess = (msg) => {
        setSnackbarSeverity("success")
        setSnackbarMessage(msg);
        setSnackbarOpen(true);
    }

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setSnackbarOpen(false);
    };

    const renderSnackbar = () => {
        return (
            <Snackbar open={snackbarOpen} autoHideDuration={5000}
                      anchorOrigin={{ vertical: 'bottom', horizontal: 'center'}} onClose={handleSnackbarClose}>
                <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        );
    }

    return (
        // Using the provider so that ANY component in our application can
        // use the values that we are sending.
        <SnackbarContext.Provider value={{ showError,
            showWarning,
            showInfo,
            showSuccess, renderSnackbar}}>
            {children}
        </SnackbarContext.Provider>
    );
};

// Finally creating the custom hook
export const useSnackbar = () => useContext(SnackbarContext);
